import { container } from 'tsyringe'
import Vue from 'vue'
import {
  VuexModule,
  Module,
  Mutation,
  Action,
  getModule,
} from 'vuex-module-decorators'
import store from '@/app/ui/store'
import { ScheduleOrder } from '@/domain/entities/ScheduleOrder'
import { EventBus, EventBusConstants, Utils } from '@/app/infrastructures/misc'
import { ScheduleOrderPresenter } from '../presenters/ScheduleOrderPresenter'
import { UpdateScheduleOrderApiRequest } from '@/data/payload/api/ScheduleOrderRequest'
import { ScheduleOrderPayload } from '@/app/infrastructures/misc/Constants/scheduleOrder'

interface ScheduleOrderState {
  isLoading: boolean
  isUpdateLoading: boolean
  dataScheduleOrder: ScheduleOrder[]
}

@Module({
  namespaced: true,
  dynamic: true,
  store,
  name: 'schedule-order',
})
class ScheduleOrderController extends VuexModule implements ScheduleOrderState {
  private presenter: ScheduleOrderPresenter = container.resolve(
    ScheduleOrderPresenter
  )
  public isLoading = false
  public isUpdateLoading = false
  public dataScheduleOrder = [new ScheduleOrder()]

  @Action({ rawError: true })
  public getAll(params: Record<string, string>) {
    this.setLoading(true)

    const formattedParams = Utils.toInstance(
      new Map(),
      JSON.stringify(params),
      'snake_case'
    )

    this.presenter
      .getAll(formattedParams)
      .then(res => {
        if (res) this.setDataScheduleOrderList(res)
      })
      .catch(error => {
        Vue.notify({
          title: 'Fetch Schedule Order List Failed',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public update(payload: ScheduleOrderPayload) {
    this.setUpdateLoading(true)
    this.presenter
      .update(
        new UpdateScheduleOrderApiRequest(
          payload.days,
          payload.schedule,
          payload.is_active
        )
      )
      .then(res => {
        EventBus.$emit(EventBusConstants.EDIT_SCHEDULE_ORDER_SUCCESS, res)
      })
      .catch(error => {
        Vue.notify({
          title: 'Update Schedule Order Failed',
          text: [400, 422].includes(error.status)
            ? error.error.message.en
            : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
        EventBus.$emit(EventBusConstants.EDIT_SCHEDULE_ORDER_SUCCESS, false)
      })
      .finally(() => {
        this.setUpdateLoading(false)
      })
  }

  @Mutation
  private setLoading(bool: boolean) {
    this.isLoading = bool
  }

  @Mutation
  private setUpdateLoading(bool: boolean) {
    this.isUpdateLoading = bool
  }

  @Mutation
  private setDataScheduleOrderList(data: ScheduleOrder[]) {
    this.dataScheduleOrder = <ScheduleOrder[]>data
  }
}

export default getModule(ScheduleOrderController)
