



















































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import Button from '@/app/ui/components/Button/index.vue'
import Badge from '@/app/ui/components/Badge/index.vue'
import DataTableV2 from '@/app/ui/components/DataTableV2/index.vue'
import DropdownSelect from '@/app/ui/components/DropdownSelect/index.vue'
import LoadingOverlay from '@/app/ui/components/LoadingOverlay/index.vue'
import ExpandableCard from '../components/ExpandableCard/index.vue'
import IconMinusCircle from '@/app/ui/assets/icon_minus_circle.vue'
import EmptyIllustration from '@/app/ui/assets/EmptyState/no_data.vue'
import dayjs from 'dayjs'
import dayjsID from 'dayjs/locale/id'
import {
  EnumSort,
  EnumStatusLabel,
  IDataCell,
  IHeaderCell,
  IOptions,
  IParams,
  sortOptions,
  statusOptions,
} from '@/app/infrastructures/misc/Constants/scheduleOrder'
import controller from '@/app/ui/controllers/ScheduleOrderController'
import { Schedule, ScheduleOrder } from '@/domain/entities/ScheduleOrder'
import { Utils } from '@/app/infrastructures/misc'

@Component({
  components: {
    Button,
    Badge,
    DataTableV2,
    LoadingOverlay,
    DropdownSelect,
    ExpandableCard,
    IconMinusCircle,
    EmptyIllustration,
  },
})
export default class ScheduleOrderOverviewPage extends Vue {
  controller = controller
  statusOptions: Array<IOptions> = statusOptions
  sortOptions: Array<IOptions> = sortOptions
  enumStatusLabel = EnumStatusLabel

  parameters: IParams = {
    status: this.statusOptions[0],
    sort: this.sortOptions[0],
  }

  headers: Array<IHeaderCell> = [
    this.headerCellMapper('No.', '5%'),
    this.headerCellMapper('Waktu Mulai', '15%'),
    this.headerCellMapper('Waktu Selesai', '15%'),
    this.headerCellMapper('Waktu Penawaran', '15%'),
    this.headerCellMapper('', '50%'),
  ]

  dataScheduleOrder: Array<
    Array<string | boolean | number | undefined | Array<Array<IDataCell>>>
  > = []

  created(): void {
    this.fetchScheduleOrder()
  }

  get params(): Record<string, string> {
    const res: Record<string, string> = {
      sort_by: this.parameters.sort.value
        ? this.parameters.sort.value
        : EnumSort.ASC,
    }

    if (this.parameters.status.value !== undefined) {
      res.is_active = this.parameters.status.value
    }

    return res
  }

  private fetchScheduleOrder(): void {
    controller.getAll(this.params)
  }

  private onFilterList = Utils.debounce(() => {
    this.fetchScheduleOrder()
  }, 200)

  private tableCellMapper(
    value: string | number | boolean,
    colWidth: string
  ): IDataCell {
    return {
      value: value,
      customStyle: {
        minWidth: colWidth,
        maxWidth: colWidth,
      },
    }
  }

  private headerCellMapper(
    title: string | number,
    colWidth: string
  ): IHeaderCell {
    return {
      title: title,
      customStyle: {
        minWidth: colWidth,
        maxWidth: colWidth,
      },
    }
  }

  private isOfferTimeLessThanEndTime(
    endTime?: string,
    offerTime?: string
  ): boolean {
    return dayjs(`2000-01-01 ${offerTime}`).isBefore(
      dayjs(`2000-01-01 ${endTime}`),
      'hour'
    )
  }

  @Watch('controller.dataScheduleOrder')
  setScheduleOrder(data: ScheduleOrder[]): void {
    if (data) {
      this.dataScheduleOrder = data.map((item: ScheduleOrder) => {
        const dayNum = item.day === 7 ? 0 : Number(item.day)
        const dayName = dayjs()
          .locale(dayjsID)
          .day(dayNum)
          .format('dddd')

        return [
          dayName || '',
          item.isActive,
          item.updatedBy || '',
          dayjs(item.updatedAt)
            .locale(dayjsID)
            .format('dddd, DD MMM YYYY') || '',
          dayNum || '',
          !item.schedule
            ? []
            : item.schedule.map((schedule: Schedule, idx: number) => {
                return [
                  this.tableCellMapper(idx + 1, '5%'),
                  this.tableCellMapper(
                    `${schedule.startTime?.slice(0, 5)} WIB`,
                    '15%'
                  ),
                  this.tableCellMapper(
                    `${schedule.endTime?.slice(0, 5)} WIB`,
                    '15%'
                  ),
                  this.tableCellMapper(
                    `${schedule.offerTime?.slice(0, 5)} WIB`,
                    '15%'
                  ),
                  this.tableCellMapper(
                    this.isOfferTimeLessThanEndTime(
                      schedule.endTime,
                      schedule.offerTime
                    ),
                    '50%'
                  ),
                ]
              }),
        ]
      })
    }
  }
}
