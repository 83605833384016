export enum EnumSort {
  ASC = 'ASC',
  DESC = 'DESC',
}

export enum EnumStatusLabel {
  ACTIVE = 'Aktif',
  INACTIVE = 'Nonaktif',
}

export enum EnumStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export enum EnumEditAction {
  SUBMIT = 'SUBMIT',
  CANCEL = 'CANCEL',
}

export interface IOptions {
  label: string
  value: string | undefined
}

export interface IParams {
  status: IOptions
  sort: IOptions
}

export interface IStyle {
  minWidth: string
  maxWidth: string
}

export interface IDataCell {
  value: number | string | boolean
  customStyle: IStyle
}

export interface IHeaderCell {
  title: number | string
  customStyle: IStyle
}

export interface ISchedule {
  id: number
  startTime: string
  endTime: string
  offerTime: string
}

export interface IEditForm {
  days: Array<number>
  schedule: Array<ISchedule>
  isActive: IOptions
  updatedAt: string
  updatedBy: string
}

export interface ScheduleOrderPayload {
  days: Array<number>
  schedule: Array<SchedulePayload>
  is_active: boolean
}

export interface SchedulePayload {
  start_time: string
  end_time: string
  offer_time: string
}

export const statusOptions: Array<IOptions> = [
  { label: 'Pilih', value: undefined },
  { label: EnumStatusLabel.ACTIVE, value: EnumStatus.ACTIVE },
  { label: EnumStatusLabel.INACTIVE, value: EnumStatus.INACTIVE },
]

export const sortOptions: Array<IOptions> = [
  { label: '00:00 - 23:59', value: EnumSort.ASC },
  { label: '23:59 - 00:00', value: EnumSort.DESC },
]
