import { SchedulePayload } from '@/app/infrastructures/misc/Constants/scheduleOrder'
import { UpdateScheduleOrderRequestInterface } from '../contracts/ScheduleOrderRequest'

export class UpdateScheduleOrderApiRequest
  implements UpdateScheduleOrderRequestInterface {
  public days?: Array<number>
  public schedule?: Array<SchedulePayload>
  public is_active?: boolean

  constructor(
    days?: Array<number>,
    schedule?: Array<SchedulePayload>,
    is_active?: boolean
  ) {
    this.days = days,
    this.schedule = schedule,
    this.is_active = is_active
  }

  public toPayload(): string {
    const data = {
      days: this.days,
      schedule: this.schedule,
      is_active: this.is_active,
    }

    return JSON.stringify(data)
  }
}
