















import { Component, Prop, Vue } from 'vue-property-decorator'
import IconMinusCircle from '@/app/ui/assets/icon_minus_circle.vue'
import Badge from '@/app/ui/components/Badge/index.vue'
import Button from '@/app/ui/components/Button/index.vue'

@Component({
  components: {
    IconMinusCircle,
    Badge,
    Button,
  },
})
export default class ExpandableCard extends Vue {
  @Prop({ default: '' }) private summaryText!: string

  private isHasSlot(slotName: string) {
    return !!this.$slots[slotName] || !!this.$scopedSlots[slotName]
  }
}
