

















import { Component, Vue, Prop } from 'vue-property-decorator'
import { Location } from 'vue-router'

@Component
export default class Button extends Vue {
  @Prop({ default: false }) private customPadding!: boolean
  @Prop({ default: undefined }) private routeTo!: Location | string | undefined
  @Prop({ default: 'normal' }) private buttonStyle!:
    | 'normal'
    | 'outline'
    | 'pill'
    | 'blue'
    | 'blue-outline'
    | 'red'
    | 'gray'
    | 'green'
    | 'transparent'

  get buttonClass() {
    return [
      'button',
      this.buttonStyle,
      {
        'default-padding': !this.customPadding,
        disabled: this.$attrs.disabled,
      },
    ]
  }
}
