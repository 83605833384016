









import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class Badge extends Vue {
  @Prop({ default: 'warning' }) private type!:
    | 'warning'
    | 'success'
    | 'error'
    | 'error-1'
    | 'expire'
    | 'cancelled'
    | 'scheduled'
    | 'paused'
    | 'bosspack'
    | 'regpack'
    | 'jagopack'
    | 'otopack150'
    | 'otopack250'
    | 'jumbopack'
    | 'bigpack'
    | 'onepack'
  @Prop({ default: 'pill' }) private badgeStyle!: 'pill' | 'normal'
  @Prop({ default: false }) private customPadding!: boolean
}
